<template>
  <div class="p-3 p-md-5 feet-background min-vh-100 text-center">
    <div class="pt-2">
      <div class="h3 fw-bold text-center">Welcome to the Strollopia builder.</div>
      <div class="fw-light">Here you can set up your stroll and add your points of interests. Have fun!</div>
    </div>
    <button type="button" class="btn btn-dark rounded-0 mt-3" data-bs-toggle="modal" data-bs-target="#modeModal">
      Build a Stroll
    </button>
    <div v-if="isIOS" class="fixed-bottom">
      <div class="popup arrow-bottom">
        <div class="popup-wrapper">
          <div class="fw-bold">Install on your iPhone!</div>
          <div class="fw-light">Tap <i class="fa fa-arrow-up-from-bracket mx-1"></i> and then add to homescreen.</div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="modeModal" tabindex="-1" aria-labelledby="modeModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-0">
        <div class="modal-header">
          <h5 class="modal-title" id="modeModalTitle">Builder Mode</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="fw-bold mb-2">Choose your build mode:</div>
          <div class="form-check">
            <input v-model="advanced" class="form-check-input" type="radio" name="modeOptions" id="modeOption1" value="false" checked>
            <label class="form-check-label fw-light" for="modeOption1">
              Basic (recommended)
            </label>
          </div>
          <div class="form-check">
            <input v-model="advanced" class="form-check-input" type="radio" name="modeOptions" id="modeOption2" value="true">
            <label class="form-check-label fw-light" for="modeOption2">
              Advanced
            </label>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <router-link to="/builder"><button class="btn btn-sm btn-dark rounded-0" data-bs-dismiss="modal">Start Building!</button></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { advancedStore } from '@/stores/advanced'

export default {
  name: 'HomeView',
  setup() {
    const store = advancedStore()
    return { store }
  },
  data() {
    return {
      advanced: null,
      isIOS: false
    }
  },
  watch: {
    advanced(newValue, oldValue) {
      if (oldValue !== null) {
        this.store.switchAdvanced()
      }
    }
  },
  methods: {
    detectIOS() {
      // Detects if device is on iOS 
      const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
      }

      // THIS IS NOT WORKING
      const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);

      // Detects if device is in standalone mode
      const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

      // Checks if should display install popup notification:
      if (isIos() && !isInStandaloneMode() && isSafari) {
        this.isIOS = true;
      }
    }
  },
  mounted() {
    this.advanced = this.store.advanced
    this.detectIOS()
  }
}
</script>

<style scoped>
.form-check-input:checked {
  background-color: #212529 !important;
  border: #212529 !important;
}
.form-check-input:focus {
    box-shadow: none;
}

.popup {
  width: 300px;
  margin: 0 auto 20px auto;
  position: relative;
  bottom: 0px;
  text-align: center;
  z-index: 999;
}
.popup:after {
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 1px 14px rgba(0,0,0,.2);
}
.popup:before {
  content: "";
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  box-shadow: 0 1px 14px rgba(0,0,0,.2);
  background: #fff;
  position: absolute;
  z-index: 998;
}
/*
Different arrow positioning
*/
.popup.arrow-top:before {
  left: calc(50% - 10px);
  top: -8px;
}
.popup.arrow-right:before {
  top: calc(50% - 10px);
  right: -8px;
}
.popup.arrow-bottom:before {
  left: calc(50% - 10px);
  bottom: -8px;
}
.popup.arrow-left:before {
  top: calc(50% - 10px);
  left: -8px;
}

/* 
Popup wrapper
This is where we add the white background and padding of the content within the popup
*/
.popup-wrapper {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  background: #fff;
  position: relative;
  z-index: 998;  
}
</style>
