import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueMobileDetection from "vue-mobile-detection";
import { createPinia } from 'pinia'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import './css/styles.css'
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far, fab)
dom.watch();


const app = createApp(App)
const pinia = createPinia()

app.use(router)
app.use(VueGoogleMaps, {
    load: {
      key: "AIzaSyDbwXMGD3Bsa8FnZdeBBHxXUKmKZFaYhmE",
    },
})
app.use(VueMobileDetection)
app.use(pinia)

app.component("font-awesome-icon", FontAwesomeIcon)

app.mount('#app')
