import { defineStore } from 'pinia'

export const advancedStore = defineStore('advanced', {
  state: () => {
    return { advanced: false }
  },
  actions: {
    switchAdvanced() {
      this.advanced = !this.advanced
    },
  },
})